<template>
  <div class="tw-p-6 md:tw-p-10 tw-drop-shadow-md tw-border-b-2">
    <div class="tw-flex tw-items-center tw-justify-between tw-mb-6">
      <router-link
        :to="{ name: 'admins' }"
        class="text-lg tw-font-semibold tw-text-blue-700 tw-hover:tx-text-blue-900">
        <i class="mr-2 pi pi-arrow-left"></i>
        Kembali
      </router-link>
      <Button label="Edit Data" 
        icon="pi pi-pencil" 
        iconPos="left" 
        class="tw-h-11 tw-text-sm tw-mr-3 tw-bg-transparent tw-text-black tw-hover:tw-bg-neutral-300 tw-border-gray-300" 
        @click="editData(data.id)"
      />
    </div>
    <h5 class="tw-text-xl inter-semibold tw-text-black">
      Detail Admin
    </h5>
  </div>

  <div class="tw-p-6 md:tw-p-10">
    <div class="grid tw-justify-center">
      <div class="col-12 lg:col-9">
        <div class="card tw-mb-10">
          <div class="grid">
            <div class="col-12 md:col-4">
              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Email
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.email }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  No. Hp
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ data.phone_number }}
                </h6>
              </div>
            </div>

            <div class="col-12 md:col-6">
              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Login Terakhir
                </h6>
                <h6 class="tw-text-md inter-medium">
                  {{ dateTime(data.last_login_time) }}
                </h6>
              </div>

              <div class="tw-mb-6">
                <h6 class="opacity-60 inter-regular tw-text-sm tw-mb-2">
                  Status
                </h6>
                <div>
                  <Tag class="status status-warning" value="InActive" v-if="data.status === 0"></Tag>
                  <Tag class="status status-info" value="Active" v-else-if="data.status === 1"></Tag>
                  <Tag class="status status-danger" value="Suspend" v-else-if="data.status === 2"></Tag>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment';

  export default {
    props: ['id'],
    data() {
      return {
        data: {},
      }
    },
    mounted () {
      this.getData();
    },
    methods: {
      editData(id) {
        this.$router.push({ name: "edit-admin", params: { id: id } });
      },
      getData() {
        this.$http
          .get(`${process.env.VUE_APP_PRITI_MAIN_API}/api/v1/auth/user/${this.id}/admin`)
          .then(response => {
            this.data = response.data.data
          })
      },
      dateTime(value) {
        return moment(value).locale('id_ID').format('dddd, DD MMMM YYYY HH:mm:ss');
      },
    },
  }
</script>

<style lang="scss" scoped>

</style>
